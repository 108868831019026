<template>
	<div class="grid">
		<div class="col-12 lg:col-12">
			<h2>Bienvenidos al panel de {{ingresoCostumer}}</h2>
		<!-- <Toolbar class="mb-4">
			
			<template v-slot:end>
				<Button label="Volver" icon="pi pi-chevron-left" class="mr-2 mb-2" @click="salirCustomer"></Button><br>
			</template>
		</Toolbar> -->
		</div>
		
	</div>
</template>

<script>
export default {
	data() {
		return {
			message: [],
			customers: [],
			username:null,
			email:null,
			count: 0,
			ingresoCostumer: ''
		}
	},
	mounted(){
		// this.cargar_empresas();
		// var correo =  document.cookie.split(";");
		// console.log(correo);
		// this.ingresoCostumer = correo[3].replace('NameCustomer=', '');
		// console.log(document.cookie);
		const cookieName = 'NameCustomer';
		let cookie = {};
		document.cookie.split(';').forEach(function(el) {
		let [key,value] = el.split('=');
		cookie[key.trim()] = value;
		});
		this.ingresoCostumer = cookie[cookieName];
		// this.ingresoCostumer = localStorage.getItem( 'NameCustomer');
	},
	methods:{
		// showSuccess(customer,index) {
			
		// 	// this.ingresoCostumer = true;
		// 	console.log(index);
		// 	// this.$router.push({ path: '/funcionarios'})
		// 	window.eventBus.$emit('showmenu');
		// 	// this.$toast.add({severity:'success', summary: 'Success Message', detail:'Message sent', life: 3000});
		// },
		salirCustomer(){
			// this.ingresoCostumer = false;
			this.$router.push({ path: this.$store.getters['infoNavigation/rol']+'/select-customer'});
			localStorage.removeItem('NameCustomer');
			localStorage.removeItem('IdCustomer');
			localStorage.removeItem('IdUsuarioRol');
			document.cookie="NameCustomer=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
			document.cookie="IdCustomer=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
			document.cookie="IdUsuarioRol=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
		},
	}
}
</script>

<style scoped>

</style>
